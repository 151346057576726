

#provider-selection-page {
    margin-top: 5%;
    margin-left: 15px;
    margin-right: 15px;

    text-align: -webkit-center;
    
    .provider-select-interests-header {
        background-color: white;
        margin-bottom: 10px;
        font-size: x-large;
        font-weight: bold;
        color: #737373 !important;
        padding-bottom: 10px;
    }

    .select-interests-divider {
        border: 1px solid #737373;
        margin-left: -50%;
        margin-right: -50%;
    }
    
    .select-interests-pane {
        display: flex;
        flex-wrap: wrap;
        margin: 0 5px;
        margin-top: 10px;
        
        .interests-container {
            width: 50px;
            height: 50px;
            flex: 1 1 25%; /* explanation below */
            margin: 26px 5px 26px 5px;
            display: table-row-group;

            .provider-name {
                color: #737373;
                font-size: 12px;
                line-height: 1.3;
            }

                .interests-checkmark {
                    margin-bottom: 4px;
                    position: absolute;
                }
        }
    }
}
