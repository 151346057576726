
#confirmation-page {
    margin-top: 10%;
    margin-left: 15px;
    margin-right: 15px;

    text-align: -webkit-center;

        p {
            margin-top: 20px;
            margin-right: 40px;
            margin-left: 40px;
        }

    .confirmed-interests-pane {
        display: flex;
        flex-wrap: wrap;
        margin: 0 5px;

        .confirmed-interests-container {
            width: 50px;
            height: 50px;
            flex: 0 1 21%;
            margin: 30px 5px 25px 5px;
            display: table-row-group;

            .provider-name {
                color: #737373;
                font-size: 12px;
                line-height: 1.3;
            }
        }
    }
    
}