
#footer-container {

    left:0;
    bottom:0;
    right:0;

    .footer-button-container {
        margin-bottom: 30px;

        .footer-confirmation-button-white {
            height: 40px;
            width: 165px;
            border-radius: 15px;
            border: none;
            background-color: white;
            border: 1px solid #008bc7;
            color: #008bc7;

        }

        .footer-confirmation-button-blue {
            height: 40px;
            width: 165px;
            border-radius: 15px;
            border: none;
            background-color:#008bc7;
            border: 1px solid #008bc7;
            color: white;

        }
    }

    .footer-logo-container {
        margin-bottom: 25px;
    }
}