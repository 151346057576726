
#customer-form-page {
    // display: flex;

    .customer-form-container {
        left:0;
        right:0;
        margin-top: 15%;

        .form-testimonial-text {
            height: 120px;
            margin-right: 40px;
            margin-left: 40px;
        }
        
        .customer-form-divider {
            margin-right: 75px;
            margin-left: 75px;
            margin-top: 55px;
            margin-bottom: 55px;
        }

        .customer-form-input-container {
            
            h5 {
                color: dimgray;
                font-family: Arial;
                font-weight: 100;
            }

            .customer-form-input {
                width: 300px;
                margin-bottom: 20px;
                font-family: Arial;
            }

            .customer-form-input-invalid-number {
                width: 300px;
                margin-bottom: 20px;
                font-family: Arial;

                    &:after {
                        content: 'Invalid Number';
                        color: red;
                        width: 100px;
                        z-index: 9999;
                        position: absolute;
                        margin-top: 9px;
                        margin-right: 10px;
                        right: 0;
                    }
            }
            
            .ui.input>input {
                border-radius: 30px;
                width: 300px;
                border: 1px solid #b5b5b5;
            }
        }

    }
}
