
#welcome-page {

    .welcome-text-container {
        position: absolute;
        bottom: 100px;
        position:absolute;
        left:0;
        right:0;
        margin: 0 auto;
        height: 80%;
        width: 100%;

            h1 {
                color: #545454;
                margin-bottom: 30px;
                font-family: Arial;
                font-size: 38px;         
            }

            p {
                color: #545454;
                margin: 5%;
                font-size: 21px;
            }

            .welcome-pg-divider {
                border: 1px solid #cfcfcf;
                margin-right: 75px;
                margin-left: 75px;
                margin-top: 45px;
            }
    }
}
